const burl = './images/achievement/gallery/';

const galleryItems = [

    {
        src: `${burl}202208-08-014338_6EZy-0.jpg`,
        title: 'Faye'
    },
    
    {
        src: `${burl}202208-08-015908_zaSI-0.jpg`,
        title: 'Spurga Best Puppy in Group 2021'
    },
    
    {
        src: `${burl}202208-08-014338_V4cI-0.jpg`,
        title: 'Faye Best Junior in Group 2022'
    },
    {
        src: `${burl}202208-08-015908_4KOT-0.jpg`,
        title: 'Faye RB Junior Show 2022'
    },
    {
        src: `${burl}202208-08-015908_b7Sn-0.jpg`,
        title: 'Winter Best Local Bred in Show Special'
    },
    {
        src: `${burl}202208-08-015908_GXFW-0.jpg`,
        title: 'Winter Best Local Bred in Show Special'
    },
    {
        src: `${burl}202208-08-015908_rjVM-0.jpg`,
        title: 'Racheal Best Puppy in Group'
    },
    {
        src: `${burl}202208-08-015908_sBiU-0.jpg`,
        title: 'Winter Champion & Best of Breed'
    },
    {
        src: `${burl}202208-08-015908_whD4-0.jpg`,
        title: 'Faye RB Junior Show 2022'
    },
    {
        src: `${burl}202208-08-014338_DkoN-0.jpg`,
        title: 'Racheal New Champion'
    },
    {
        src: `${burl}202208-08-014338_G2aM-0.jpg`,
        title: 'Spurga Posting'
    },
];

export const items = galleryItems;
export const boxsize = 256 + 128;
