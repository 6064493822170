const burl = './images/children/gallery/';

const galleryItems = [
    {
        src: `${burl}202203-16-101920_Tefc-0.jpg`,
        rows: 1,
        cols: 1,
        title: '',
    },
    {
        src: `${burl}202203-21-104534_h7ql-0.jpg`,
    },
    {
        src: `${burl}202203-21-104537_cxjD-0.jpg`,
    },
    {
        src: `${burl}202203-21-104538_ElvU-0.jpg`,
    },
    {
        src: `${burl}202203-21-104540_Uuh7-0.jpg`,
    },
    {
        src: `${burl}202203-21-104542_3UI0-0.jpg`,
    },
    {
        src: `${burl}202203-21-104542_5QUj-0.jpg`,
    },
    {
        src: `${burl}34065404_996995080460449_1895676132116660224_n.jpg`,
    },
    {
        src: `${burl}202203-21-104542_eL88-0.jpg`,
    },
    {
        src: `${burl}202203-21-104542_lXx5-0.jpg`,
    },
    {
        src: `${burl}202203-21-104542_mj1G-0.jpg`,
    },
    {
        src: `${burl}202203-21-104542_nnAq-0.jpg`,
    },
    
    {
        src: `${burl}202203-21-104543_Dw5L-0.jpg`,
    },
    {
        src: `${burl}202203-21-104545_jsLx-0.jpg`,
    },
    {
        src: `${burl}202203-21-104547_4PdB-0.jpg`,
    },
    {
        src: `${burl}202203-21-104550_B3qC-0.jpg`,
    },
    {
        src: `${burl}300009918_171859895394239_175318955938995737_n.jpg`,
    },
    {
        src: `${burl}202203-21-104552_iBLI-0.jpg`,
    },
    {
        src: `${burl}202203-21-104554_QUND-0.jpg`,
    },
    {
        src: `${burl}202203-21-104556_d1SA-0.jpg`,
    },
    {
        src: `${burl}202203-21-104558_7p1z-0.jpg`,
    },
    {
        src: `${burl}202203-21-104600_SbxX-0.jpg`,
    },
    {
        src: `${burl}202203-21-104602_meB4-0.jpg`,
    },
    {
        src: `${burl}202203-21-104604_wFu2-0.jpg`,
    },
    {
        src: `${burl}202203-21-104606_IMQE-0.jpg`,
    },
    {
        src: `${burl}202203-21-104608_23yA-0.jpg`,
    },
    {
        src: `${burl}32072881_378752149272137_1462723173614092288_n.jpg`,
    },
    {
        src: `${burl}33744765_995678510592106_6322196867643867136_n.jpg`,
    },
    {
        src: `${burl}33777439_995678520592105_502708381350363136_n.jpg`,
    },
    
    
];

export const items = galleryItems;
export const boxsize = 256+128;