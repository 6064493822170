import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import { useTranslation } from 'react-i18next';

import "./breed_profile.css";

import SubHeader from "../subheader/subheader.js";

export default function Breed_profile(){
    const { t, i18n } = useTranslation('breed_profile');
    
    return(
        <Container fluid className="breed_profile p-0">
            <SubHeader header1={t('title')} header3={t('title2')}></SubHeader>
            <Container className="mt-5 whitebox-shadow rounded">
                <br/>
                <div className="d-flex flex-row flex-warp  ">
                    <img src="/images/commons/winter.jpg" className="winter img-fluid p-0 m-1 ani_fadeIn" />
                    <div >
                        <p className="context m-3 ani_fadeIn">{t('para1')}</p>
                        <p className="context m-3 ani_fadeIn d-none d-md-block">{t('para2')}</p>
                    </div>
                </div>
                <p className="context m-3 ani_fadeIn d-block d-md-none">{t('para2')}</p>
                <br/>
                <div className="context m-3">
                    <p className="para3"><h4>
                        {t('title3')}
                    </h4></p>
                    <p className="para3">{t('para3')}</p>
                    <br/>

                    <p className="para4"><h4>
                        {t('title4')}
                    </h4></p>
                    <p className="para4">{t('para4')}</p>
                    <br/>

                    <p className="para5"><h4>
                        {t('title5')}
                    </h4></p>
                    <p className="para5">{t('para5')}</p>
                    <p className="para5">{t('para6')}</p>
                    <p className="para5">{t('para7')}</p>
                    <br/>
                </div>
                
            </Container>
            <br/>
        </Container>
    )
}