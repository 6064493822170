//TODO merge this into single libs for all project, for now version control manually 2023-12-28
// axios.js
import axios from "axios";
import { useCooker } from "./cooker";

let cooker;


export const useNCAPI = () => {
  cooker = useCooker();
  // axios.defaults.baseURL = 'https://crow-router.pnix.exchange';
  axios.defaults.withCredentials = true;
  axios.defaults.cache = false;

  // axios.interceptors.request.use(
  //   (config) => {
  //     const token = cooker.accessToken();
  //     if (token) {
  //       // config.headers["Authorization"] = `Bearer ${cooker.accessToken()}`;  // for Spring Boot back-end
  //       // config.headers["x-access-token"] = token; // for Node.js Express back-end
  //       config.headers["Origin"] = "https://pnix.exchange"
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // // Set up an interceptor to refresh the token before it expires
  // const interceptor = axios.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const originalRequest = error.config;
      
  //     // if ((error.config.url != '/auth' && originalRequest.method === 'post')       //dont retry on auth path prevent auto refresh lock
  //     //   && error.response?.status === 401     //do retry only on 401 response
  //     //   && !originalRequest._retry            //dont retry more than once
  //     //   && (cooker.refreshToken() != null))   //dont retry when refreshtoken is none
  //     // {
  //     //   originalRequest._retry = true;

  //     //   // Refresh the access token using the refresh token
  //     //   await asyncMutexRefreshToken();

  //     //   if (cooker.accessToken()) {
  //     //     originalRequest.headers['Authorization'] = `Bearer ${cooker.accessToken()}`;
  //     //     return axios(originalRequest);
  //     //   }
  //     // }
  //     // if(error.config)
  //     console.log(error);
  //     // return axios(originalRequest);
  //     return Promise.reject(error);
  //   }
  // );
}

export async function post(path, body, headers) {
  // init();
  return await axios.post(
    path,
    body,
    {
      headers: { 
        // "Content-Type": "application/json",  //this will enable CORS
        // "X-Api-Key": apiKey,
        // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
        ...headers,
      },
      withCredentials: false,
    }
  );
}

export async function put(path, body, headers) {
  // init();
  return await axios.put(
    path,
    body,
    {
      headers: { 
          // "Content-Type": "application/json",
        //   "X-Api-Key": apiKey,
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
      },
      withCredentials: false,
    }
  );
}

export async function get(path, body, headers) {
  // init();
//   console.log('axios.get', apiKey);
  return await axios.get(
    path,
    {
        headers: { 
            // "Content-Type": "application/json",
            // "X-Api-Key": apiKey,
            // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
            ...headers,
        },
        withCredentials: false,
    },
    body,
  );
}