import { Link } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import Typography from "@mui/material/Typography";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import React, { useState, useEffect } from 'react'

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { useTranslation } from 'react-i18next';

import * as galleryItems from './galleryItems.js';
import "./achievement.css"

import SubHeader from "../subheader/subheader.js";

export default function Achievement(){
    // const [openLighBi, setOpen] = useState(false);
    const { t, i18n } = useTranslation('achievement');

    const [index, setIndex] = React.useState(-1);
    
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

    function calImageListCol(mincol, maxcol){
        return Math.min(Math.max(Math.round(getCurrentDimension().width / galleryItems.boxsize), mincol),maxcol);
    }
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])

    
    return(
        <Container fluid className='achievement p-0'>
            <SubHeader header1={t('title')} sizemode='small' bgcolor='orange-pink'></SubHeader>
            <br/>
            <Lightbox
                    index={index}
                    slides={galleryItems.items}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />

            <div className="mx-auto p-2 ">
                <ImageList cols={ calImageListCol(1,3) } style={{maxWidth: galleryItems.boxsize * calImageListCol(1,3) +'px'}} className="mx-auto">
                {galleryItems.items.map((item, index) => (
                    <ImageListItem key={index} cols={item.cols || 1} rows={item.rows || 1}
                        className="m-0 p-0"
                        style={{    maxHeight: (galleryItems.boxsize * (item.rows || 1) + ((item.rows || 1) - 1) * 4 ) + 'px', 
                                    maxWidth: (galleryItems.boxsize * (item.cols || 1)) + 'px' }}
                        onClick={({ index: current }) => {console.log(index + ":" + current) ; setIndex(index);}} 
                        
                        >
                        <img src={item.src} alt={item.title || ''} 
                            className="gallery-list-item link-icon img-fluid m-0 p-0" 
                            
                            />
                        {/* </a> */}
                    </ImageListItem>
                ))}
                </ImageList>
            </div>

            <div>
                <iframe width="560" height="316" src="https://www.youtube.com/embed/LW8_ZwpI0Ns" 
                    title="Landmark@Grand Station_Pet Lovers" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    className="iframe-vid p-2"
                    allowfullscreen>
                </iframe>
            </div>
        </Container>
    )
}