import React, { useEffect } from 'react';

const AdSenseAd = ({ client, slot, format, responsive }) => {
    useEffect(() => {
        // Load AdSense script asynchronously
        const script = document.createElement('script');
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`;
        // script.async = true;
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        // Listen for script load event
        script.onload = () => {
            // Initialize AdSense
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        };
    
        return () => {
            // Cleanup on unmount if needed
            script.onload = null;
        };
    }, [client]);

  return (
    <ins className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}>
    </ins>
  );
};

export default AdSenseAd;