import React, { useState, useEffect } from 'react';

import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';

import './header.css';


export default function Header(props) {
    const location = useLocation();
    const { t, i18n } = useTranslation('header');
    const [isth, setlang] = useState(true)
    // const pathname = window.location.pathname;
    const [pathname, setPath] = useState('');
    const [navCollapse, setNavCollapse] = useState(false);

    const changeLanguage = bt => {
        setlang(!isth);
        if(isth){
            i18n.changeLanguage('en');
        }else{
            i18n.changeLanguage('th');
            
        }
    }

    //navbar scroll when active state
    const [navbarAway, setNavbar] = useState(props.alwayCollapse?'navaway':'')

    //navbar scroll changing function
    const checkNavbarAway = () => {
        if(props.alwayCollapse){
            setNavbar('navaway')
        }
        else if (window.scrollY >= 128) {
            setNavbar('navaway')
        } else {
            setNavbar('')
        }
    }

    function toggleNavCollapse(){
        setNavCollapse(!navCollapse);
    }

    useEffect(() => {
        checkNavbarAway();
        // adding the event when scroll change background
        window.addEventListener("scroll", checkNavbarAway)
    })

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);
    

    function createMenuLink(path) {
        if(('/'+path)===pathname || (path==='home' && pathname==='/')){
            return( <div className=' blue-em my-auto p-2'><b>{t(path)}</b></div> );
        }
        return(<Nav.Link EventKey="0" as={Link} to={'/'+path} onClick={toggleNavCollapse}>{t(path)}</Nav.Link>);
    }

    return(
        <Navbar collapseOnSelect className={'navbar mx-auto ' + navbarAway + (props.alwayCollapse?' alwaycollapse':'')} expand="lg" sticky='top' bg='light'>
            {/* Desktop Menu */}
            <Container fluid className='maxw-1920 navbar-container-lg mx-auto d-none d-lg-block'>
                    <Row className='navbar-row mx-auto'>
                        <Col md="auto" className='navbar-col1'>
                            <Navbar.Brand href="/home">
                                <Image fluid className={"img-logo mx-auto " + navbarAway} 
                                    src='/images/commons/logo.png' alt="Paws Nation Westie Kennel" />
                            </Navbar.Brand>
                        </Col>

                        <Col className='navbar-col2'>
                        <div fluid className='navbar-context-lg'>
                            <div className={'navbar-contact ' + navbarAway}>
                                <div className='navbar-contact-top'>
                                    <a href='https://www.facebook.com/PawsNationWestieKennel'>
                                        <Image fluid className='link-icon header-icon mx-1' src='/images/commons/i-face.png' alt="facebook" />
                                    </a>
                                    <a href='https://page.line.me/pawsnation'>
                                        <Image fluid className='link-icon header-icon mx-1' src='/images/commons/i-line.png' alt="line" />
                                    </a>
                                    <button className='navbar-lang' onClick={changeLanguage}>
                                        <Image fluid className='link-icon header-icon mx-1' src={isth ? '/images/header/flag_th.png' : '/images/header/flag_en.png' } alt="lang" />
                                    </button>
                                </div>
                                <div className='navbar-contact-bottom'>
                                    <div>
                                        <Image src='/images/commons/i-phone.png' alt='phone'/>
                                        <span> {t('tel')}</span>
                                    </div>
                                    <div>
                                        <Image src='/images/commons/i-mail.png' alt='mail'/>
                                        <span> focuscia3407@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                            
                            <Nav fluid className='navbar-nav'>
                                {createMenuLink('home')}
                                {createMenuLink('breed_profile')}
                                {createMenuLink('children')}
                                {createMenuLink('councils')}
                                {createMenuLink('achievement')}
                                {createMenuLink('contact_us')}
                            </Nav>
                        </div>
                    </Col>
                    </Row>
            </Container>
            

            {/* Mobile Menu */}
            <Container fluid className='navbar-container-md mx-auto d-lg-none'>
                <Navbar.Brand href="/home" className='d-flex flex-row'>
                        <Image fluid className={"img-logo-md mx-auto me-2 " + navbarAway} src='/images/commons/logo.png' alt="Paws Nation Westie Kennel" />
                        <div className='d-flex flex-row flex-wrap my-auto'>
                            <span className='navbar-text1-md'>Paws Nation</span>
                            &nbsp;
                            <span className='navbar-text2-md'>Westie Kennel</span>
                        </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='' onClick={toggleNavCollapse}/>
                <Navbar.Collapse id="basic-navbar-nav" className={'navbar-collapse'} in={navCollapse}>
                    <div fluid className='navbar-nav'>
                            {createMenuLink('home')}
                            {createMenuLink('breed_profile')}
                            {createMenuLink('children')}
                            {createMenuLink('councils')}
                            {/* {createMenuLink('story')} */}
                            {createMenuLink('achievement')}
                            {createMenuLink('contact_us')}
                        <hr></hr>
                        <div>
                            <a href='https://www.facebook.com/PawsNationWestieKennel'>
                                <Image fluid className='link-icon header-icon mx-1' src='/images/commons/i-face.png' alt="facebook" />
                            </a>
                            <a href='https://page.line.me/pawsnation'>
                                <Image fluid className='link-icon header-icon mx-1' src='/images/commons/i-line.png' alt="line" />
                            </a>
                            <button className='navbar-lang' onClick={changeLanguage}>
                                <Image fluid className='link-icon header-icon mx-1' src={isth ? '/images/header/flag_th.png' : '/images/header/flag_en.png' } alt="lang" />
                            </button>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}