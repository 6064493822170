const burl = './images/home/gallery/';


const galleryItems = [
        {
            src: `${burl}202203-16-101920_IXIg-0.jpg`,
            rows: 2,
            cols: 2,
            title: '',

        },

        {
            src: `${burl}202203-16-101920_Tefc-0.jpg` 
        },
        {
            src: `${burl}202203-16-101920_Xvsf-0.jpg` 
            
        },
        {
            src: `${burl}202203-16-101921_7ftK-0.jpg` 
        },
        {
            src: `${burl}202208-08-021139_6XEH-0.jpg`,
            title: 'cowboy westie'
        },
        {
            src: `${burl}202208-08-021139_PSKd-0.jpg`,
            title: 'purple glass'
        },
        {
            src: `${burl}202203-16-101921_k2oA-0.jpg` 
        },

        {
            src: `${burl}75593764_1431765020316784_8996809569938178048_n.jpg`,
            cols: 2
        },

        {
            src: `${burl}202203-16-101921_6qxl-0.jpg`,
            
        },
        
        {
            src: `${burl}202203-16-101921_E0LB-0.jpg`,
            
        },
        {
            src: `${burl}202203-16-101921_YFJf-0.jpg` 
        },
        {
            src: `${burl}202208-08-021139_OHob-0.jpg` 
        },


        {
            src: `${burl}202203-16-101921_IP2E-0.jpg`
        },
        {
            src: `${burl}202203-16-101921_XSUK-0.jpg` 
        },
        {
            src: `${burl}31895197_377019892778696_5113785274630930432_n.jpg`,
            cols: 2
        },
        {
            src: `${burl}275844634_2156299091196703_7588994596137263062_n.jpg` 
        },
        
        {
            src: `${burl}Clipboard02.jpg` ,
        },
        {
            src: `${burl}32072881_378752149272137_1462723173614092288_n.jpg` ,
            cols: 1
        },
        {
            src: `${burl}Clipboard03.jpg`
        },
        {
            src: `${burl}33343547_993089054184385_6422325066553360384_o.jpg`
        }
        
];

export const items = galleryItems;
export const boxsize = 256;
