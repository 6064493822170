import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';


import './subheader.css';


export default function SubHeader(props){
    const { t, i18n } = useTranslation('subheader');
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

    useEffect(() => {
        const updateDimension = () => {
              setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

    
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
  }, [screenSize])

    function doHeader1(){
        return <p><h1 className=' whitepink inner-shadow'>{props.header1}</h1></p>;
    }

    function doHeader2(){
        return <p><h2 className=' whitepink'>{props.header2}</h2></p>;
    }

    function doHeader3(){
        return <p><h3 className=' whitepink'>{props.header3}</h3></p>;
    }

    function backgroundSyle(){
        if(props.bgcolor == 'blue'){
            return ' bg-blue';
        }

        if(props.bgcolor == 'green' ){
            return ' bg-green';
        }

        if(props.bgcolor == 'orange-pink' ){
            return ' bg-orange-pink';
        }

        return ' bg-blue';
    }

    return(
        <Container fluid className={'subheader m-0 p-0 mx-auto ' + (getCurrentDimension().width < 768 ? 'small' : props.sizemode) + backgroundSyle()}>
            <Container fluid className='mx-auto d-flex justify-content-between maxw-1920'>
                <div className='subheader-left my-auto d-flex flex-start'>
                    <div className='ms-auto my-auto'>
                        {doHeader1()}
                        {doHeader2()}
                        {doHeader3()}
                    </div>
                </div>
                <div className='p-0 m-0 subheader-right'>
                    {/* <figure> */}
                        <img id="winter" src='/images/home/i-bg-winter.png'></img>
                        <img id="winter-foot" src='/images/home/i-bg-foot.png'></img>
                    {/* </figure> */}
                </div>
            </Container>
        </Container> 
    )
}