
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Cookies from 'universal-cookie';

const PRE_ACCESS_TOKEN_EXPIRE_PERIOD = 5 * 60000;   //60000 = 1min
// const PRE_ACCESS_TOKEN_EXPIRE_PERIOD = (23 * 60 * 60000) + (59 * 60000); 

export const useCooker = () => {
    const cookies = new Cookies();

    function setTokencalData(data){
        cookies.set('/nightcrows/data', data);
    }

    function getTokencalData(){
        return cookies.get('/nightcrows/data');
    }
    return{
        setTokencalData, getTokencalData,
    };
}
