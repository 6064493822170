
import { Link } from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { useTranslation } from 'react-i18next';

import "./councils.css";
import "./namecards"
import { namecards } from "./namecards";

import SubHeader from "../subheader/subheader.js";

export default function Councils(){
    const { t, i18n } = useTranslation('councils');

    const cardBgColors = ['bgpink', 'bgblue', 'bggreen'];

    function nameCard(info, is_right, bgcolor){
        return(
            <Container className={"namecard d-flex p-2 my-2 " 
                                    + bgcolor
                                    + (is_right ? ' flex-row-reverse me-2' : ' flex-row ms-2')}>
                
                <div className="namecard-img">
                    <img src={info.img || '/images/commons/null.png'} className="img-fluid"
                        style={{backgroundImage: `url('/images/commons/bg-logo.png')`}}
                    ></img>
                </div>
                <div className="description flex-fill ms-3 whitepink inner-shadow">
                    <div className="title my-auto p-0 m-0">
                            {info.nickname || <br/>}
                        <hr className="mt-0 pt-0 me-2 "></hr>
                    </div>
                    <br/>
                    <div className="detail">
                        <Row className="d-flex flex-wrap">
                            <Col xs='3'>Fullname:</Col>
                            <Col xs='8'>{info.fullname || ''}</Col>
                        </Row>
                        <Row className="d-flex flex-wrap">
                            <Col xs='3'>Title:</Col>
                            <Col xs='8'>{info.achievement || ''}</Col>
                        </Row>
                        <Row className="d-flex flex-wrap">
                            <Col xs='3'>Birth:</Col>
                            <Col xs='8'>{info.birth || ''}</Col>
                        </Row>
                        <Row className="d-flex flex-wrap">
                            <Col xs='3'>Sex:</Col>
                            <Col xs='8'>{info.sex || ''}</Col>
                        </Row>
                    </div>
                </div>
            </Container>
        );
    }

    // function random100(){
    //     return(
    //         Math.random()*100 % 100
    //     );
    // }

    return(
        <Container fluid className="councils p-0">
            <SubHeader header1={t('title')} sizemode='small' bgcolor='green'></SubHeader>
            <br/>
            {/* <div className="spotlight ani_circling deco-circle1" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/>
            <div className="spotlight ani_circling deco-circle2" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/>
            <div className="spotlight ani_circling deco-circle1" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/>
            <div className="spotlight ani_circling deco-circle2" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/>
            <div className="spotlight ani_circling deco-circle1" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/>
            <div className="spotlight ani_circling deco-circle2" 
                style={{left: random100() + 'vw', top: random100() + 'vh'}}/> */}
            <Container fluid className="context maxw-lg d-flex flex-column justify-content-start mx-auto">
                {
                    namecards.map((card, index) =>{
                        return(
                            nameCard(card, index%2, cardBgColors[index%3])
                        )
                    })
                }
            </Container>
            
        </Container>
    )
}
