import context from 'react-bootstrap/esm/AccordionContext';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
// import Box from '@mui/material/Box';

import './footer.css';

export function Footer(){
    const { t, i18n } = useTranslation('footer');

    return(
        <footer id='footer' className='mx-auto'>
            <Container fluid className='maxw-1920 mx-auto'>
                <br/>
                <div className='footer-content d-flex flex-wrap'>
                    <div className='footer-content-1 mx-auto ani-fadeInLeft'>   
                        <h3 className='blue-em'><b>{t('contact')}</b></h3>
                        <b>ครอบครัวเวสตี้ (ฟาร์มเวสตี้) <br></br>
                        Paws Nation Westie Kennel</b>
                        <p></p>
                        <p>{t('address')}</p>
                    </div>
                    <div className='footer-content-2 mx-auto ani-fadeInRight'>
                        <p></p>
                        <div>
                            <a href='https://www.facebook.com/PawsNationWestieKennel'>
                                <img className='link-icon footer-social-icon mx-2' src='/images/commons/i-f-ins.png' alt="instagram" />
                            </a>
                            <a href='https://www.youtube.com/@pawsnationwestiekennel7245'>
                                <img className='link-icon footer-social-icon mx-2' src='/images/commons/i-f-yt.png' alt="youtube" />
                            </a>
                            <a href='https://www.facebook.com/PawsNationWestieKennel'>
                                <img className='link-icon footer-social-icon mx-2' src='/images/commons/i-f-face.png' alt="facebook" />
                            </a>
                            <a href='https://page.line.me/pawsnation'>
                                <img className='link-icon footer-social-icon mx-2' src='/images/commons/i-f-line.png' alt="line" />
                            </a>
                        </div>
                        <p></p>
                        <div>
                            <img src='/images/commons/i-phone.png' alt='phone'/>
                            <span> {t('tel')}</span>
                        </div>
                        <div className="">
                            <img className="" src='/images/commons/i-mail.png' alt='mail'/>&nbsp;<span>focuscia3407@gmail.com</span>
                        </div>
                        <p></p>
                        
                    
                        
                    </div>
                </div>
                <div className='copyright'>
                    <p></p>
                    <p>Copyright© 2022,  Paws Nation All rights reserved.</p>
                </div>
            </Container>
        </footer>
    )
}
export default Footer;