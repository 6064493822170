import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as NCAPI from '../utils/NCAPI'
import AdSenseAd from '../utils/AdSenseAd';


import "./tokencal.css"
import { useCooker } from '../utils/cooker';

export default function NGTokencal() {
    // const { t, i18n } = useTranslation('home');
    const initialized = useRef(false);
    const cooker = useCooker();
    NCAPI.useNCAPI();

    function initToken(name, pair, image, multiple){
        return ({
            name: name,
            pair: pair,
            image: image,
            text: `${name} (x${multiple})`,
            // buyoffer: '-',
            // selloffer: '-',
            avg20offer: '-',
            lastoffer: '-',
            maxoffer: '-',
            minoffer: '-',
            lastday: 0,
            multiple: multiple,
            marketdia: 0,
            cashout: 0,
        })
    }

    const [data, setData] = useState(
        ()=> {
            const cookie = cooker.getTokencalData();
            if(cookie)return cookie;

            return{
                lastupdate: null,
                cashout: 0,
                crows: {
                    name: 'crows',
                    pair: '',
                    image: 'https://cache.pnix.exchange/symbols/CROW.png',
                    text: 'crows',
                    mint: 84,
                    unpack: 80,
                    dollar: 0.73
                    },
                tokens: [
                    initToken(
                        'Morion',
                        "0x19690c8ecd26a82853d5a9059480be15dc7884a4",
                        'https://cache.pnix.exchange/symbols/MORION.png',
                        10
                    ),
                    initToken(
                        "payrus",
                        "0x9d5949c408ca8a41b5ef322bd078e1b7e16ce628",
                        "https://cache.pnix.exchange/symbols/PAPYRUS.png",
                        10,
                    ),
                    initToken(
                        "Gear",
                        "0x2ae7e7cbbcbe04fa274d4f743c15eb1fff2b789c",
                        "https://cache.pnix.exchange/symbols/GEAR.png",
                        1,
                    ),
                    initToken(
                        "Promote",
                        "0x0c82e7e5a95a0d599bff88d0b7f7b0ec2b2c2002",
                        "https://cache.pnix.exchange/symbols/PROMOTE.png",
                        1,
                    ),
                    initToken(
                        "Tear",
                        "0x8697bed5499737c10ab5adb4bc7952fe457b50e0",
                        "https://cache.pnix.exchange/symbols/TEAR.png",
                        1,
                    ),
                    initToken(
                        "Feather",
                        "0x7d82933a4e9ed20dd11008d6a0beaa5c4b0b2687",
                        "https://cache.pnix.exchange/symbols/FEATHER.png",
                        1,
                    ),
                ]
            }
        }
        
    );

    // const [minRate, setMinRate] = useState(0);
    // function calmin(){
    //     setMinRate(0);
    //     data.tokens.forEach((item) =>{
    //         if(minRate <=0) setMinRate(item.cashout);
    //         if(item.cashout < minRate)setMinRate(item.cashout);
    //         if(item.cashout != 0)console.log(item.cashout)
    //     })
    //     // console.log(minRate);
    //     // return minRate;
    // }

    useEffect(() => {
        // console.log(minRate);
        var minRate = 0;
        data.tokens.forEach((item) =>{
            if(minRate <=0) minRate = (item.cashout);
            if(item.cashout != 0 && item.cashout < minRate)minRate = (item.cashout);
            // if(item.cashout != 0)console.log(item.cashout)
        })

        if(minRate != 0){
            setData((prev) =>({
                ...prev,
                cashout: minRate,
            }))
        }

        
    }, [data]); // Log minRate whenever it changes


    useEffect(() => {
        // if(data?.crows?.mint)
            cooker.setTokencalData(data);
    }, [data]);

    const getCrow = async(e) => {
        //0x4e72379066a0c28d7ce5bcd51d155eb24ade0e9c
        // try{
        //     const response = await NCAPI.get(
        //         'https://wemixplay.com/api/pnix-tokens',
        //         JSON.stringify({ 
                   
        //         }),
        //     )
        //     // console.log(response.data)

        //     response.data.result.forEach((item) =>{
        //         if(item.address === '0x4e72379066a0c28d7ce5bcd51d155eb24ade0e9c'){
        //             // console.log(item.base)
        //             setData((prev) =>({
        //                 ...prev,
        //                 crows: {
        //                     ...prev.crows,
        //                     dollar: item.base.oraclePrice,
        //                 }
        //                 // cashout: calmin(prev),
        //             }))
        //         }
        //     })
            
    
        // }catch(err){
        // }finally{
        // }

        try{
            const response = await NCAPI.post(
                'https://crow-router.pnix.exchange/dex',
                JSON.stringify({ 
                    id: 1,
                    jsonrpc: "2.0",
                    method: "dex_getAllPair",
                    params: [],
                }),
            )

            //response.data.result[0].quote.name === 'CROW'

            setData((prev) =>({
                ...prev,
                crows: {
                    ...prev.crows,
                    dollar: response.data.result[0].quote.oraclePrice,
                }
                // cashout: calmin(prev),
            }))
            
        }catch(err){
        }finally{
        }
    }

    

    const getAllTokenDex = async(e) => {
        await getCrow();

        await Promise.all(data.tokens.map(async (token) =>{
            await getTokenDex(token);
            getDailyDex(token);
        }))
        

        

        setData((prev) =>({
            ...prev,
            lastupdate: (new Date()).toLocaleTimeString(),
            // cashout: calmin(prev),
        }))

        // Call your function here
        setTimeout(getAllTokenDex, 1000);
    }

    
    //Get Token
    const getTokenDex = async(token, e) => {
        // var maxbuy = 0;
        // var minsell = Number.MAX_VALUE;
        // console.log(token)
        var count = 0;
        var total = 0;

        try{
            const response = await NCAPI.post(
                'https://crow-router.pnix.exchange/dex',
                JSON.stringify({ 
                    id: 1,
                    jsonrpc: "2.0",
                    method: "dex_getLatestMeasuresByPair",
                    params: [
                        token.pair
                    ]
                }),
            )

            
            // console.log(response.data.result);
            const lastoffer = response.data.result[0].price;
            response.data.result.forEach(function(item){
                // if(item.side == 0){ //sell
                //     if(item.price < minsell) minsell = item.price;
                // }else{  //buy
                //     if(item.price > maxbuy) maxbuy = item.price;
                // }
                total += item.price * item.volume;
                count += item.volume;
            })
            const avg = total/count;

            setData((prev) =>({
                ...prev,
                // tokens: [...prev.tokens],
                tokens: prev.tokens.map((item, index) => {
                    if(token.pair === item.pair){
                        
                        var maxoffer = 0;
                        var minoffer = 0;
                        //find maxoffer / minoffer
                        if(avg>0){
                            maxoffer = minoffer = avg;
                        }
                        if(lastoffer > maxoffer)maxoffer = lastoffer;
                        if(lastoffer > 0 && lastoffer < minoffer)minoffer = lastoffer;

                        const cashout = item.marketdia * 1.05 / minoffer * item.multiple;
                        // console.log(`${item.name}: ${minoffer} ${maxoffer}` );
                        return {
                            ...item,
                            // buyoffer: maxbuy,
                            // selloffer: minsell,
                            lastoffer: lastoffer,
                            avg20offer: avg,
                            minoffer: minoffer,
                            maxoffer: maxoffer,
                            cashout: cashout
                        }
                    }
                    return item;
                })
            }))
        }catch(err){
        }finally{
        }
    }

    const getDailyDex = async(token, e) => {
        const yesterdayUnixTime = Math.floor(Date.now() / 1000) - 86400 + 28800;    //UTC+8
        // const yesterdayUnixTime = Math.floor((new Date() - 86400000) / 1000);    //UTC+0
        try{
            const response = await NCAPI.post(
                'https://crow-router.pnix.exchange/dex',
                JSON.stringify({ 
                    id: 1,
                    jsonrpc: "2.0",
                    method: "dex_chartV2",
                    params: [
                        token.pair, 4, yesterdayUnixTime, 1
                    ]
                }),
            )

            // console.log(response.data.result);
            setData((prev) =>({
                ...prev,
                // tokens: [...prev.tokens],
                tokens: prev.tokens.map((item, index) => {
                    if(token.pair === item.pair){
                        return {
                            ...item,
                            lastday: response.data.result[0].c,
                        }
                    }
                    return item;
                })
            }))
            // console.log(response);
            
        }catch(err){
        }finally{
        }
    }

    //Run once to send email on page load
    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true
        
        // console.log(cooker.getTokencalData());
        // setData(cooker.getTokencalData());

        getAllTokenDex();

        // Cleanup function (optional)
        return () => clearTimeout();

    }, [cooker.getTokencalData]);

    const setCrowMintRate = (newValue) =>{
        setData((prev) =>({
            ...prev,
            crows: {
                ...prev.crows,
                mint: newValue,
            }
        }))
    }

    const setTokenMarketPrice = (newValue, pair) => {
        setData((prev) =>({
            ...prev,
            tokens: prev.tokens.map((item, index) => {
                if(item.pair === pair){
                    return {
                        ...item,
                        marketdia: newValue,
                    }
                }
                return item;
            })
        }))
    }
    
    const handleAmountChange = (e, setValueFunction, key) => {
        const input = e.target.value;

        // Regex to allow only numbers with two digits after the decimal point
        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(input) || input === '') {
            setValueFunction(input, key)
        }
    }


    function prettyDecimal(text, digits){
        const parsedNumber = parseFloat(text);
        if (!isNaN(parsedNumber)) {
            // If it's a number, format it to two decimal places
            return parsedNumber.toLocaleString('en-US', {maximumFractionDigits: digits?digits:2, minimumFractionDigits: digits?digits:2});
            // return parsedNumber.toFixed(digits?digits:2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
        // If it's not a number, return the original text
        return text;
        }
    }

    return(
        <div className='NGtokencal mt-4 px-1'>
            <Container className='NGtable p-0'>
                <div className='NGHeader'>
                    <video playsinline loop autoPlay className='NGHeadervid w-100 m-0 p-0'>
                        <source src="https://cache.pnix.exchange/v2/assets/video/NightCrowMarket.mp4" 
                            type="video/mp4" />
                    </video>
                    <div className='NGHeader-context w-75'>
                        <Row className='text-align-start mt-4 mb-3 mx-2'><h3>Night Crows Tokenamic helper</h3></Row>
                        <Row className='text-align-start mx-2'>
                            <Col xs={12} className='ms-0 ps-0 text-align-end'>
                                <Container className='mb-2'>
                                    <Row className='my-2'>
                                        <Col xs={1} className='text-align-start mt-1'>
                                            Crow:
                                        </Col>
                                        <Col xs="auto" className='text-align-start d-flex'>
                                            <div className='mt-1'>Mint:</div><input className='NCdecimalinput-tiny ms-1 mt-0' value={data.crows.mint} onChange={(e) => handleAmountChange(e, setCrowMintRate)}/>
                                        </Col>
                                        <Col className='text-align-start d-flex'>
                                            <div className='ms-1 mt-1'>/ Burn: {data.crows.unpack} => ( {prettyDecimal(data.crows.dollar,4)}<span className='text-golden'>$</span> )</div>
                                        </Col>
                                    </Row>
                                    <Row className='my-2 mb-3'>
                                        <Col xs={1} className='text-align-start mt-1'>
                                            Cashout:
                                        </Col>
                                        <Col xs="auto" className='text-align-start mt-1 d-flex nowarp'>
                                            Crow: {prettyDecimal((1000 / data.crows.mint) * data.crows.dollar)}<span className='text-golden'>$</span>
                                        </Col>
                                        <Col xs="auto" className='text-align-start mt-1 d-flex'>
                                            / Other: {prettyDecimal(1000 / data.cashout * data.crows.dollar)}<span className='text-golden'>$</span>
                                        </Col>
                                        <Col xs={4} className='text-align-start text-small ms-2 mt-2'>
                                                *( 8.5<span className='text-golden'>$</span> ~ 300<span className='text-golden'>฿</span>, 6<span className='text-golden'>$</span> ~ 200<span className='text-golden'>฿</span> )
                                        </Col>
                                    </Row>
                                    <Row className='text-align-start text-small ms-2 mt-2'>
                                        
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
                
                <Row className='NCheader-row py-2 mx-0 mt-0 mb-4'>
                    <Col xs={3} lg={2}>Token Name</Col>
                    <Col xs={2} lg={1}>LastOffer (Crows)</Col>
                    <Col xs={1} className='d-sm-none d-lg-block'>avg20 (Crows)</Col>
                    <Col xs={1} className='d-sm-none d-lg-block'>Buy (dia)</Col>
                    <Col xs={1} className='d-sm-none d-lg-block'>Sell (dia)</Col>
                    <Col xs={2}>Market (dia)</Col>
                    <Col>Market Profit (%)</Col>
                    <Col>Token Profit (%)</Col>
                    <Col>Cash out (dia)</Col>
                </Row>
                {
                    data.tokens.map(function(item){
                        var buydia = item.minoffer * data.crows.mint / item.multiple;
                        var selldia = item.maxoffer * data.crows.mint / item.multiple;
                        var mintfee = (item.lastday * 80 * 0.05)/item.multiple;
                        return(
                            <Row className='NCdata-row my-2 px-4'>
                                <Col xs={3} lg={2} className='NCdata-col'>
                                    <div className='d-flex nowarp'><img className='header-icon me-2' src={item.image} /><div className='mt-1'>{item.text}</div></div>
                                </Col>
                                <Col xs={2} lg={1} className='text-align-end mt-1'>
                                    {prettyDecimal(item.lastoffer)}
                                </Col>
                                <Col xs={1} className='text-align-end mt-1 d-sm-none d-lg-block'>
                                    {prettyDecimal(item.avg20offer)}
                                </Col>
                                <Col xs={1} className='text-align-end mt-1 d-sm-none d-lg-block'>
                                    {prettyDecimal(buydia)}
                                </Col>
                                <Col xs={1} className='text-align-end mt-1 d-sm-none d-lg-block'>
                                    {prettyDecimal(selldia)}
                                </Col>
                                <Col xs={2} className='text-align-end'>
                                    {/* {prettyDecimal(item.marketdia)} */}
                                    <input className='NCdecimalinput mt-0' value={item.marketdia} onChange={(e) => {handleAmountChange(e, setTokenMarketPrice, item.pair)}} />
                                </Col>
                                {/* //market profit  > Buy Token > sell to diamond */}
                                <Col className=' mt-1'> 
                                    {prettyDecimal(parseFloat(item.marketdia) * 0.95 / selldia * 100 - 100)}%
                                </Col>
                                {/* Token Profit > Buy Item by diamond sell to crows */}
                                <Col  className=' mt-1'>
                                    {prettyDecimal((buydia * 0.95 / (parseFloat(item.marketdia) + mintfee)) * 100 - 100)}% 
                                </Col>
                                <Col  className=' mt-1'>
                                    {prettyDecimal(item.cashout)}
                                </Col>
                                <hr className='my-3'/>
                            </Row>
                        )
                    })
                }
                
                <div className='text-small mx-2 text-align-start'>
                    Last update: {data.lastupdate}
                </div>
                <div className='text-small mx-2 mb-4 pb-2 text-align-start'>
                    *market fee > on sold: 5% + on shelf: ~{prettyDecimal(1000/(60000/150))}%
                </div>
            </Container>
            <AdSenseAd client="ca-pub-7158983862163990" slot="8458451580" format="auto" responsive="true" />
        </div>
    )
}