import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

import React, { Suspense } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import GA4React from "react-ga4";


import Header from "./header/header.js"
import Footer from "./footer/footer.js"
import Home from "./home/home.js"
import Children from './children/children';
import Breed_profile from './breed_profile/breed_profile';
import Achievement from './achievement/achievement';
import Contact_us from './contact_us/contact_us';
import Councils from './councils/councils';
import NGTokencal from './nightcrows/tokencal'


// Create a custom theme with Bootstrap breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576, // Bootstrap's "sm" breakpoint, mui 600
      md: 768, // Bootstrap's "md" breakpoint, mui 900
      lg: 992, // Bootstrap's "lg" breakpoint, mui 1200
      xl: 1200, // Bootstrap's "xl" breakpoint, mui 1536
      xxl: 1400, // Bootstrap's "xxl" breakpoint
      xxxl: 1920, // My special max-eyes-width
    },
  },
});

const baseTitle = 'Paws Nation Westie';
const pageTitles = {
  '/home' : '',
  '/breed_profile' : 'Breed Profile',
  '/children' : 'Our Children',
  '/achievement' : 'Our Achievement',
  '/contact_us' : 'Contact us',
  '/councils' : 'The Counclis',
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

function App() {
  const location = useLocation();
 

  // init GA4
  useEffect(() =>{
    GA4React.initialize("G-LVFDGLQXM4");
  }, []);

  //update title and GA4 on location change
  useEffect(() =>{
    // let title = history.location.pathname
    document.title = `${baseTitle}` 
      + (pageTitles[location.pathname] ? ' - ' + pageTitles[location.pathname] : '');


    GA4React.gtag('event','page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* <BrowserRouter> */}
          <ScrollToTop/>
          <Suspense fallback="..loading">
            <Routes>
              <Route path='/nightcrows/*' element={<Header alwayCollapse/>} />
              <Route path='*' element={<Header />} />
            </Routes>

            <Routes>
              <Route exact path='/nightcrows/tokencal' element={<NGTokencal />} title={`${baseTitle} - nightcrows-tokencal`} />
              
              <Route path='/' element={<Home />} />
              <Route exact path="/home" element={<Home />} title={`${baseTitle} - Home`} />
              <Route exact path="/breed_profile" element={<Breed_profile />} title={`${baseTitle} - Breed Profile`} />
              <Route exact path="/children" element={<Children />} title={`${baseTitle} - Chilred`} />
              <Route exact path="/achievement" element={<Achievement />} title={`${baseTitle} - Achievement`} />
              <Route exact path='/contact_us' element={<Contact_us />} title={`${baseTitle} - Contact us`} />
              <Route exact path='/councils' element={<Councils />} title={`${baseTitle} - Counclis`} />
              <Route path='*' element={<Navigate replace to='/home' />} />
            </Routes>

            <Routes>
              <Route path='*' element={<Footer />} />
            </Routes>
          </Suspense>
        {/* </BrowserRouter> */}

        </ThemeProvider>
    </div>
  );
}

export default App;
