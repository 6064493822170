export const namecards = [
    {
        img: "",
        nickname: "Lilith",
        fullname: "Lilith of Paws Nation",
        achievement: "",
        birth: "10/03/2023, Thailand",
        sex: "Female",
    },
    {
        img: "",
        nickname: "You",
        fullname: "Paws Nation, We Will Rock You",
        achievement: "",
        birth: "07/05/2021, Thailand",
        sex: "Female",
    },
    {
        img: "/images/councils/adele.jpg",
        nickname: "Dele",
        fullname: "Adele of Paws Nation",
        achievement: "",
        birth: "18/11/2020, Thailand",
        sex: "Female",
    },
    {
        img: "/images/councils/faye.jpg",
        nickname: "Faye",
        fullname: "Faye of Paws Nation",
        achievement: "Thai Champion",
        birth: "04/10/2020, Thailand",
        sex: "Female",
    },
    {
        img: "/images/councils/rubrick.jpg",
        nickname: "Rubrick",
        fullname: "Doble O Nada New Gryffindor",
        achievement: "",
        birth: "05/07/2019, Spain",
        sex: "Male",
    },
    {
        img: "",
        nickname: "Achill",
        fullname: "Snowflake M.K. Toy Kennel",
        achievement: "",
        birth: "20/04/2019, Thailand",
        sex: "Female",
    },
    {
        img: "/images/councils/spurga.jpg",
        nickname: "Spurga",
        fullname: "Baila Morena Pliusas",
        achievement: "Thai Champion",
        birth: "28/03/2019, Lithuania",
        sex: "Female",
    },
    {
        img: "/images/councils/racheal.jpg",
        nickname: "Racheal",
        fullname: "Mcqueen M.K. Toy Kennel",
        achievement: "Thai Champion",
        birth: "17/01/2019, Thailand",
        sex: "Female",
    },
    {
        img: "/images/councils/winter.jpg",
        nickname: "Winter",
        fullname: "Winter of Paws Nation",
        achievement: "Thai Champion",
        birth: "03/04/2018, Thailand",
        sex: "Male",
    },
];

export const discharge = [
    {
        img: "",
        nickname: "Beebee",
        fullname: "Colonel Beebee of Paws Nation",
        achievement: "",
        birth: "19/06/2019, Thailand",
        sex: "Female",
    },
    {
        img: "",
        nickname: "Elsa",
        fullname: "Pang Homm",
        achievement: "",
        birth: "09/03/2017, Thailand",
        sex: "Female",
    },
    {
        img: "",
        nickname: "Eclair",
        fullname: "Sky Eclair of Wild Westies",
        achievement: "",
        birth: "09/11/2016, Thailand",
        sex: "Female",
    },
    
]

export const memorials = [
    {
        img: "",
        nickname: "Rifle",
        fullname: "Paws Nation White Candy",
        achievement: "",
        birth: "22/08/2021",
        sex: "Female",
        dead: "28/07/2023",
    },
];