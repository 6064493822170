import {Container, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";

import "./contact_us.css"

import SubHeader from "../subheader/subheader.js";

export default function Contact_us(){
    // const [openLighBi, setOpen] = useState(false);
    const { t, i18n } = useTranslation('contact_us');

    return(
        <Container fluid className='contact_us p-0'>
            <SubHeader header1={t('title')} sizemode='small' bgcolor='green'></SubHeader>
            <br />
            <div className="inner-container whitebox-shadow d-flex maxw-xl flex-row flex-wrap mx-auto p-2 justify-content-center rounded">
                <Container fluid className='content namecard justify-content-start my-auto mx-2 px-4 py-2 border'>
                    <Row>
                        <Col><h3><l className='t1'>Paws Nation </l><l className='t2'>- Westie Kennel</l></h3></Col>
                    </Row>
                    <Row className='py-1'>
                        <Col>{t('address')}</Col>
                    </Row>
                    <br/>
                    <Row className='py-1'>
                            <Col xs='1'>
                                <a href={'tel:' + t('tel')}><img className='link-icon img-icon' src='/images/commons/i-phone.png'></img></a>
                            </Col>
                            <Col xs='9'>
                                <a href={'tel:' + t('tel')}>{t('tel')}</a>
                            </Col>
                    </Row>
                    <Row  className='py-1'>
                            <Col xs='1'>
                                <a href='mailto:focuscia3407@gmail.com'><img className='link-icon img-icon' src='/images/commons/i-mail.png'></img></a>
                            </Col>
                            <Col xs='9'>
                                <a href='mailto:focuscia3407@gmail.com'>focuscia3407@gmail.com</a>
                            </Col>
                    </Row>
                    <Row  className='py-1'>
                            <Col xs='1'>
                                <a href='https://www.facebook.com/PawsNationWestieKennel'><img className='link-icon img-icon' src='/images/commons/i-f-face.png' alt="facebook" /></a>
                            </Col>
                            <Col xs='9'>
                                <a href='https://www.facebook.com/PawsNationWestieKennel'>Paws Nation-Westie</a>
                            </Col>
                    </Row>
                    
                    <Row  className='py-1'>
                        
                            <Col xs='1'>
                                <a href='https://page.line.me/pawsnation'><img className='link-icon img-icon' src='/images/commons/i-f-line.png' alt="line" /></a>
                            </Col>
                            <Col xs='9'>
                                <a href='https://page.line.me/pawsnation'>@pawsnation</a>
                            </Col>
                    </Row>
                    
                    <div className='deco'>
                        <img src='/images/contact_us/faye.png' className='faye'></img>
                    </div>
                    <br/>
                </Container>
                <div className='flex-fill map ms-2 me-1 my-4 py-auto'>
                    <iframe allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7750.464485981996!2d100.53238962497419!3d13.764863548952391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf06ead498dad419!2z4Lif4Liy4Lij4LmM4Lih4LmA4Lin4Liq4LiV4Li14LmJIFBhd3MgTmF0aW9uIC0gV2VzdGllIEtlbm5lbA!5e0!3m2!1sen!2sth!4v1664313673501!5m2!1sen!2sth" 
                         width="100%"
                        className='map m-auto'></iframe>
                </div>
            </div>
            <br/>
        </Container>
    )
}