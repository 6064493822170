import { Link } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import Typography from "@mui/material/Typography";
import {Container, Row, Col} from 'react-bootstrap';
// import {CContainer} from '@coreui/bootstrap-react';
import Image from 'react-bootstrap/Image';
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import * as galleryItems from './galleryItems.js';
import "./home.css"

export default function Home(){
    const { t, i18n } = useTranslation('home');
    const [index, setIndex] = React.useState(-1);

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

    function calImageListCol(){
        return Math.min(Math.max(Math.round(getCurrentDimension().width / galleryItems.boxsize), 2),5);
    }
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])



    return(
        <div className="home p-0">
            {/* <img className="heart1" src="./images/home/heart1.png" /> */}
            {/* <img className="heart2" src="./images/home/heart1.png" /> */}
            {/* <img className='welcome' src='./images/home/HomeWelcome.jpg' alt="welcome" /> */}
            <Container fluid className="welcome">
                <div className="bg" />
                <div className="welcome-img maxw-1920">
                    
                    <img src="/images/home/welcome-dog3.png" className="dog3" />
                    <div className="dog-group2">
                        <img src="/images/home/welcome-2dogs.png" className="dog2 mx-auto" />
                        <img src="./images/home/heart.png" className="heart3" />
                        <img src="./images/home/heart.png" className="heart4" />
                    </div>
                    
                    <div className="dog-group1">
                        <img src="/images/home/welcome-dog1.png" className="dog1 mx-auto" />
                        <img src="./images/home/heart.png" className="heart1" />
                        <img src="./images/home/heart.png" className="heart2" />
                    </div>
                    <div className="eclipse-container">
                        <img src="/images/home/welcome-eclipse.png" className="eclipse mx-auto" />
                    </div>
                    <div className="img-clover"></div>
                </div>

            </Container>
            
            <Container fluid className='mx-auto'>
                <Container fluid className='lboxs_container ani_fadeIn'>
                    <Row className="maxw-1200 mx-auto">
                        <Col className="lbox-item m-auto py-0 px-0 col-4">
                            <Link to='/breed_profile'>
                                <div className="lbox-context item1 p-3">
                                    <div className="lbox-title whitepink inner-shadow">
                                        {t('lbox1-title')}
                                    </div>
                                    <div className="lbox-detail">
                                        {t('lbox1-detail')}
                                    </div>
                                </div>
                                <img src='./images/home/lbox1.png' className='lbox-img img-fluid' alt='' />
                                <div className="rmbox rounded px-4 py-1 link-icon">Readmore</div>
                            </Link>
                        </Col>
                        <Col className="lbox-item m-auto py-3 px-0 col-4">
                            <Link to='/councils'>
                                <div className="lbox-context item2 p-3">
                                    <div className="lbox-title whitepink inner-shadow">
                                        {t('lbox2-title')}
                                    </div>
                                    <div className="lbox-detail">
                                        {t('lbox2-detail')}
                                    </div>
                                </div>
                                <img src='./images/home/lbox2.png' className='lbox-img img-fluid' alt='' />
                                <div className="rmbox rounded px-4 py-1 link-icon">Readmore</div>
                            </Link>
                        </Col>
                        <Col className="lbox-item m-auto py-3 px-0 col-4">
                            <Link to='/children'>
                                <div className="lbox-context item3 p-3">
                                    <div className="lbox-title whitepink inner-shadow">
                                        {t('lbox3-title')}
                                    </div>
                                    <div className="lbox-detail">
                                        {t('lbox3-detail')}
                                    </div>
                                </div>
                                <img src='./images/home/lbox3.png' className='lbox-img img-fluid' alt='' />
                                <div className="rmbox rounded px-4 py-1 link-icon">Readmore</div>
                            </Link>
                        </Col>
                    </Row>

                    {/* <Link to='/breed_detail'>
                        <img src='./images/home/b-dog1.jpg' alt='' style={{marginTop: 5,marginBottom: 25}}></img>
                    </Link>
                    <Link to='/main_member'>
                        <img src='./images/home/b-dog2.jpg' alt='' style={{marginBottom: 25}}></img>
                    </Link>
                    <Link to='/children'>
                        <img src='./images/home/b-dog3.jpg' alt='' style={{marginBottom: 25}}></img>
                    </Link> */}
                </Container>
                <div className='ani-fadeInTop maxw-992 mx-auto'>
                    <p></p>
                    <h2>{t('title')}</h2>
                    <p></p>
                    <p>
                        {t('landing_word')}
                    </p>
                    <p>&nbsp;</p>
                </div>
            </Container>

            <Lightbox
                    index={index}
                    slides={galleryItems.items}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />

            <div className="mx-auto p-3 ">
                <ImageList cols={ calImageListCol() } style={{maxWidth: galleryItems.boxsize * calImageListCol() +'px'}} className="mx-auto">
                {galleryItems.items.map((item, index) => (
                    <ImageListItem key={index} cols={item.cols || 1} rows={item.rows || 1}
                        className="m-0 p-0 link-icon whitebox-shadow"
                        style={{    maxHeight: (galleryItems.boxsize * (item.rows || 1) + ((item.rows || 1) - 1) * 4 ) + 'px', 
                                    maxWidth: (galleryItems.boxsize * (item.cols || 1)) + 'px' }}
                        onClick={({ index: current }) => {console.log(index + ":" + current) ; setIndex(index);}} 
                        
                        >
                        <img src={item.src} alt={item.title || ''} 
                            className="gallery-list-item img-fluid m-0 p-0" 
                            loading="lazy"
                            />
                        {/* </a> */}
                    </ImageListItem>
                ))}
                </ImageList>
            </div>

            <div className="body-end">
                <div className="body-end-context whitepink inner-shadow ani_fadeIn">
                    <div className="deco">
                        <div className="deco-circle1 bcolor-fade-white"></div>
                        <div className="deco-circle2 bcolor-fade-white"></div>
                    </div>
                    <div className='mx-auto'>
                        <img id="i-bg-winter" src='./images/home/i-bg-winter.png'></img>
                        <img id="i-bg-foot" src='./images/home/i-bg-foot.png'></img>
                        <br/>
                        <p className="body-end-txt">{t('more_detail1')}</p>
                        <p className="body-end-txt">{t('more_detail2')}</p>
                        <br/>

                        {/* <Link to="https://www.facebook.com/PawsNationWestieKennel">
                            <button className="bt-contact bt-face link-icon">
                                <img src='./images/commons/i-face-wh.png'></img>
                                &nbsp; Paws Nation &nbsp;
                            </button>
                        </Link> */}
                        <Link to="https://page.line.me/pawsnation">
                            <button className="bt-contact bt-line link-icon">
                                <img src='./images/commons/i-line-wh.png'></img>
                                &nbsp; Paws Nation&nbsp;
                            </button>
                        </Link>
                        <Link to={'tel:' + t('tel')}>
                            <button className="bt-contact bt-face link-icon">
                                <img src='./images/commons/i-tel-wh.png'></img>
                                &nbsp; Paws Nation&nbsp;
                            </button>
                        </Link>
                    </div>
                    
                    {/* <img className="i-bg-winter" src='./images/home/dog-bg-wh.png'></img> */}
                    
                </div>
            </div>
        </div>
    )
    
}
